






































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import { BaseStore } from '#/store/base'

@Component
export default class BoxCoupon extends Vue {
    @Prop({ type: Object }) coupon!: ICouponProducer
    @Prop({ type: Number }) index!: number

    neverExpire = true

    @BaseStore.State('licenseOptionsLight') licenseOptionsLight: ILicenseOptionLight[]

    removeCoupon() {
        this.$emit('callRemoveCoupon', this.index)
    }

    @Watch('coupon', { deep: true })
    onCouponChange() {
        this.$emit('callUpdateCoupon', this.coupon, this.index)
    }
    @Watch('neverExpire')
    onNeverExpireChange(newValue: boolean) {
        if (newValue) this.coupon.expiration_date = null
    }
}
