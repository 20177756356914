import { get, update, remove, post } from '~/services/http-requests'

const _BS_COUPONS = 'coupons'

/*
 **** GET ****
 */

function fetchAllCoupons(): Promise<ICouponProducer[]> {
    return get({ baseProperty: _BS_COUPONS })
}

/*
 **** POST ****
 */
function createCoupon(coupon: ICouponProducer | Partial<ICouponProducer>): Promise<ICouponProducer> {
    return post({ baseProperty: _BS_COUPONS, dispatchParams: coupon })
}

/*
 **** PATCH ****
 */
function updateCoupon(coupon: ICouponProducer | Partial<ICouponProducer>): Promise<ICouponProducer> {
    return update({ baseProperty: _BS_COUPONS, fullUrl: `${_BS_COUPONS}/${coupon.id}/`, dispatchParams: coupon })
}

/*
 **** DELETE ****
 */
function removeCoupon(couponId: ICouponProducer['id']) {
    return remove({ baseProperty: _BS_COUPONS, fullUrl: `${_BS_COUPONS}/${couponId}/` })
}

export { fetchAllCoupons, createCoupon, updateCoupon, removeCoupon }
