


























import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import BoxCoupon from '#/components/BoxCoupon.vue'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { fetchAllCoupons, removeCoupon, createCoupon, updateCoupon } from '#/api/coupon'

@Component({ components: { VLoadSpinner, BoxCoupon } })
export default class CouponsPage extends Vue {
    isLoading = true
    coupons: Array<Partial<ICouponProducer>> = []

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    addBlankCoupon() {
        const defaultCoupon: Partial<ICouponProducer> = {
            token: '',
            discount_percentage: 50,
            licenses: [],
            isNew: true,
            usage_available_is_infinite: true
        }
        this.coupons.push(defaultCoupon)
    }

    async fetchAllCoupons() {
        this.isLoading = true
        const couponsResponse = await fetchAllCoupons()
        couponsResponse.forEach(coupon => (coupon.isNew = false))
        this.coupons = couponsResponse
        this.isLoading = false
    }

    setUpdatedPropertyOnCoupon(coupon: ICouponProducer, index: number) {
        this.coupons[index] = coupon
        if (!coupon.isNew) this.coupons[index].isUpdated = true
    }

    async saveCoupons() {
        Promise.all(
            this.coupons.map(async coupon => {
                // convert all in uppercase
                coupon.token = coupon.token.toUpperCase()
                // if there's no usage_available_max we pass it as 0, neeed by backend
                if (!coupon.usage_available_max) coupon.usage_available_max = 0
                // api calls
                try {
                    if (coupon.isNew) await createCoupon(coupon)
                    else if (!coupon.isNew && coupon.isUpdated) await updateCoupon(coupon)
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
                    this.fetchAllCoupons()
                } catch (err) {
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
                    // add error to display to the user
                    coupon.error = 'Code already taken, try another'
                }
            })
        )
    }

    async removeCoupon(couponIndex: number) {
        try {
            const coupon = this.coupons[couponIndex]
            if (!coupon.isNew) await removeCoupon(coupon.id)
            // remove locally
            this.coupons.splice(couponIndex, 1)
            // show success message
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
            // re-fetch coupons
            this.fetchAllCoupons()
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    openConfirmActionModal(couponIndex: number) {
        this.SET_ACTIVE_MODAL({ modal: ModalType.ConfirmAction, callback: () => this.removeCoupon(couponIndex) })
    }

    mounted() {
        this.fetchAllCoupons()
    }
}
